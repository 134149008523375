<template>
  <div>
    <div v-if="$hasPermissions(clientSession, ['ASSET_REPORT'], 1)">
      <vue-headful :title="pageTitle + ' - ' + idToPeriod()" />
      <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
        <h1 class="is-size-6 has-text-white" style="padding: 5px 0px"
        >{{ pageheading.toLocaleUpperCase() + ' - ' + idToPeriod() }}</h1>
      </div>
      <div style="max-width: 95%; margin: auto;">
        <div v-if="isDownloading || isRegenerating" id="body-content-area">
            <div style="width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column">
               <h1 class="title is-4">
                {{ isDownloading ? 'Downloading' : 'Regenerating' }} Report{{ selectedRows.length > 1 || selectedRows.length === 0 ? 's' : '' }}. This may take a moment.
              </h1>
              <p style="margin-top: -1rem;">
                Please do not close this window or navigate away from this page.
              </p>
            </div>
            <Loading />
        </div>
        <div v-else id="body-content-area">
          <div :class="{ 'hidden': !isLoading || !isDownloading || !isRegenerating }">
            <spin-loader class="center-it"/>
          </div>
          <div :class="{ 'visible': isLoading && !isDownloading }">

          <div class="top-bar-spacer" style="height: 8px; width: 100%;">&nbsp;</div>

          <div>
            <div style="display: flex; justify-content: flex-end; margin-bottom: 1rem;" class="control grid-button top-bar">
              <div id="buttongroup">
              <div class="level">
                <div class="level-left">
                  <div class="field is-grouped">
                    <div class="control">
                      <button class="button is-dark" :disabled="selectedRows.length === 0" @click="openDeleteModal">
                        <span class="icon">
                          <i class="fal fa-plus-square"></i>
                        </span>
                        <span>
                          Delete Selected
                        </span>
                      </button>
                    </div>
                    <div class="control">
                      <button
                        class="button is-dark"
                        :disabled="selectedRows.length === 0"
                        @click="e => regenerateSelected(
                          selectedReports,
                          idToPeriod(true),
                          'asset-report',
                          this,
                          'isRegenerating',
                           _=> {
                            deselect(selectedReports, 'selected');
                            selectedRows = [];
                            getAssetReports();
                          }
                        )">
                        <span  style="margin-right: 5px; margin-left: -3px;">
                          <i class="fas fa-redo" v-if="!isRegenerating"></i>
                          <i class="fas fa-spinner fa-spin" v-else></i>
                        </span>
                        <span v-if="!isRegenerating">Regenerate Selected</span>
                        <span v-else>Regenerating Selected</span>
                      </button>
                    </div>
                    <div class="control">
                      <Select
                        :buttonText="selectedRows.length === 0 ? 'Download All' : 'Download Selected' "
                        :options="[{
                          name: 'Download as Excel',
                          value: 'xlsx',
                          action: () => selectedRows.length === 0 ? downloadAll('xlsx') : download('xlsx')
                        },{
                          name: 'Download as PDF',
                          value: 'pdf',
                          action: () => selectedRows.length === 0 ? downloadAll('pdf') : download('pdf')
                        }]"
                      />
                    </div>
                    <div class="control">
                      <button :disabled="isLoadingAssetReports" class="button is-light" @click="getAssetReports">
                        <span class="icon">
                          <i v-if="!isLoadingAssetReports" class="fas fa-sync-alt"></i>
                          <i v-else class="fas fa-spinner fa-spin"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>

          <div>

            <div ref="divWrapper">
              <!-- <div class="timer">
                {{ intervalCount }}s until next refresh
              </div> -->
              <Grid
                v-if="maxHeight > 0"
                ref="reportGrid"
                :style="{height: maxHeight + 'px'}"
                :filter="filter"
                :data-items="institutionReports"
                :selected-field="selectedField"
                :sortable="true"
                :sort="sort"
                :filterable="true"
                :pageable="{
                  ...pageable,
                  pageSizes: [...pageSizes, 'All']
                }"
                :page-size="pageSize"
                :skip="pagination.skip"
                :take="pagination.take"
                :total="pagination.total"
                :columns="columns"
                @pagechange="pageChangeHandler"
                @sortchange="sortChangeHandler"
                @filterchange="filterChangeHandler"
                @selectionchange="onSelectionChange"
                @headerselectionchange="onHeaderSelectionChange"
              >
              <template v-slot:downloadTemplate="{props}">
                <td class="has-text-centered">
                  <a class="download" v-show="['PDF Complete', 'XLSX Complete', 'Both Complete'].includes(props.dataItem.status) || props.dataItem.hasPDF" @click="managingReportId = props.dataItem.id">
                    <i class="fas fa-download"></i>
                  </a>
                  &nbsp;
                  <a class="download"  v-if="['PDF Complete', 'Both Complete'].includes(props.dataItem.status) || props.dataItem.hasPDF" :href="'#'" @click.prevent="$router.push({
                    name: 'ARBatchReportPreview',
                    params: {
                      reportId: props.dataItem.id,
                      id: $route.params.id
                    }
                  })">
                    <i class="fas fa-eye"></i>
                  </a>
                  &nbsp;
                  <a class="download" :href="'#'" @click.prevent="regenerate(props.dataItem)">
                    <i v-if="!props.dataItem.isLoading" :class="['fas fa-redo', props.dataItem.isLoading ? 'fa-spin' : '']"></i>
                    <i v-else class="fas fa-spinner fa-spin"></i>
                  </a>
                </td>
              </template>
              <template v-slot:statusCell="{props}">
                <td>
                  <span>{{ /* props.dataItem.hasXLSX && props.dataItem.hasPDF ? "Completed" : */ props.dataItem.status }}</span>
                </td>
              </template>
              <template v-slot:statementDateTemplate="{props}">
                <td>
                  <span>{{ formatDate(moment(props.dataItem.statementDate).endOf('month')) }}</span>
                </td>
              </template>
              <template v-slot:creationDateTemplate="{props}">
                <td>
                  <span>{{ formatDate(props.dataItem.createdAt) }}</span>
                </td>
              </template>
              <template v-slot:batchNameCell="{props}">
                <td class="editable">
                  <span>
                    <v-popover
                      style="height: 100%; width: 100%; text-overflow: clip!important;"
                      :open="props.dataItem.showSaveDialog"
                      :placement="'right-end'"
                      :trigger="'manual'"
                      :autoHide="false"
                    >
                      <div class="" style="width: 100%;">
                        <input
                          style="height: 100%; width: 100%; text-overflow: clip!important;"
                          v-on:blur="props.dataItem.showSaveDialog = ($event.target.value !== props.dataItem.reportDisplayName); props.dataItem.event = $event; props.dataItem.reportDisplayName = $event.target.value"
                          v-on:keyup.esc="reset($event, props.dataItem); props.dataItem.event = null; props.dataItem.showSaveDialog = false;"
                          v-on:keyup.enter="props.dataItem.showSaveDialog = ($event.target.value !== props.dataItem.reportDisplayName); props.dataItem.event = $event; props.dataItem.reportDisplayName = $event.target.value"
                          :value="props.dataItem.reportDisplayName">
                      </div>

                      <!-- This will be the content of the popover -->
                      <template slot="popover" id="popover">
                        <div class="popover-contain">
                          Are you sure?
                          <div class="actions">
                            <button cancel @click.prevent="reset($event, props.dataItem); props.dataItem.event = null; props.dataItem.showSaveDialog = false;">Cancel</button>
                            <button @click.prevent="submitChanges(props.dataItem, 'reportDisplayName'); props.dataItem.event = null; props.dataItem.showSaveDialog = false;">Save</button>
                          </div>
                        </div>
                      </template>
                    </v-popover>
                  </span>
                </td>
              </template>
              <template v-slot:headerCell="{props}">
                <td
                  style="cursor: pointer!important; background-color: #fafafa!important;"
                  @click.prevent="() => {
                    if (props.dir === undefined) props.dir = 'asc';
                    else if (props.dir === 'asc') props.dir = 'desc';
                    else props.dir = undefined;

                    sortChangeHandler({
                      sort: [{
                        field: props.field,
                        dir: props.dir
                      }]
                    })
                  }"
                >
                  <a href="javascript:void()">
                    <span v-if="props.dir === 'asc' && sort.find(_rec => _rec.field === 'batchName')" class="k-icon k-i-sort-asc-sm"></span>
                    <span v-if="props.dir === 'desc' && sort.find(_rec => _rec.field === 'batchName')" class="k-icon k-i-sort-desc-sm"></span>
                    <span style="color: black;">{{ props.title }}</span>
                  </a>
                </td>
              </template>
              <template v-slot:generationCell="{props}">
                <td>
                  {{ props.dataItem.stats.generated }} / {{ props.dataItem.stats.scheduled }}
                </td>
              </template>
              <template v-slot:reportTypeFilterSlotTemplate="{props, methods}">
                <div class="k-filtercell">
                  <div class="k-filtercell-wrapper">
                    <input
                      type="text"
                      class="k-textbox"
                      placeholder="Search File Name"
                      :id="`${props.field}`"
                      :value="props.value != '' ? props.value : null"
                      @input="(ev) => {
                        methods.change({
                          operator: 'contains',
                          field: props.field,
                          value: ev.target.value,
                          syntheticEvent: ev
                        });
                      }"
                    >
                  </div>
                </div>
              </template>

              <template v-slot:creationDateFilterCellTemplate="{props, methods}">
                <div class="k-filtercell">
                  <div class="k-filtercell-wrapper">
                    <input
                      type="text"
                      class="k-textbox"
                      placeholder="yyyy-mm-dd"
                      :id="`${props.field}`"
                      :value="props.value != '' ? props.value : null"
                      @input="(ev) => {
                        methods.change({
                          operator: 'contains',
                          field: props.field,
                          value: ev.target.value,
                          syntheticEvent: ev
                        });
                      }"
                    >
                  </div>
                </div>
              </template>
            </Grid>
            </div>
          </div>

          <div id="select-type-modal" class="modal is-active" v-if="showTypeSelectionModal">
            <div class="modal-background" @click="showTypeSelectionModal = false; managingReportId = null"></div>
            <div class="modal-card modal-sm" style="max-width: 600px;">
                <header class="modal-card-head has-bg-info">
                  <div class="modal-card-title has-bg-info">Select Type</div>
                  <a class="delete" aria-label="close" @click="showTypeSelectionModal = false; managingReportId = null"></a>
                </header>
                <section class="modal-card-body">
                  <div class="content">
                    Please select the report format that you wish to download for: <b>{{managingReport.reportName}}</b>.
                  </div>
                </section>
                <footer class="modal-card-foot" style="justify-content: space-between;">
                  <a class="button is-primary" :disabled="!['Completed', 'XLSX Complete'].includes(managingReport.status) && !managingReport.hasXLSX" @click="(managingReport.hasXLSX || ['Completed', 'XLSX Complete'].includes(managingReport.status)) ? download('xlsx', managingReportId) : null" style="flex-grow: 1;">
                    <span class="icon">
                      <i class="fa fa-download"></i>
                    </span>
                    <span>Download as Excel</span>
                  </a>
                  <a class="button is-primary" :disabled="!['Completed', 'PDF Complete'].includes(managingReport.status) && !managingReport.hasPDF" @click="(['Completed', 'PDF Complete'].includes(managingReport.status) || managingReport.hasPDF) ? download('pdf', managingReportId) : null" style="flex-grow: 1;">
                    <span class="icon">
                      <i class="fa fa-download"></i>
                    </span>
                    <span>Download as PDF</span>
                  </a>
                  <a class="button" @click="showTypeSelectionModal = false">Cancel</a>
                </footer>
              </div>
          </div>

          <div id="delete-modal" class="modal is-active" v-if="showDeleteModal">
              <div class="modal-background" @click="showDeleteModal = false"></div>
              <div class="modal-card">
                <header class="modal-card-head has-bg-danger">
                  <div class="modal-card-title has-bg-danger">Confirm Delete</div>
                  <a class="delete" aria-label="close" @click="showDeleteModal = false"></a>
                </header>
                <section class="modal-card-body">
                  <div class="content">
                    Type "<b>delete</b>" to confirm that you want to delete one or more items.
                  </div>
                  <div class="field">
                    <div class="control">
                      <input type="text" class="input" placeholder="type `delete` and then click confirm" v-focus
                        v-model.trim="confirmationText" @keydown.enter.prevent="deleteIfConfirmed" />
                    </div>
                  </div>
                </section>
                <footer class="modal-card-foot">
                  <a class="button is-danger" @click="deleteIfConfirmed" :disabled="confirmationText !== 'delete'">
                    <span class="icon">
                      <i class="fal fa-trash-alt"></i>
                    </span>
                    <span>Confirm</span>
                  </a>
                  <a class="button" @click="showDeleteModal = false">Cancel</a>
                </footer>
              </div>
            </div>
        </div>
      </div>
    </div>
    </div>
    <div v-else>
      <vue-headful :title="pageTitle" />
      <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
        <h1 class="is-size-6 has-text-white" style="padding: 5px 0px"
        >No Permission</h1>
      </div>

      <center>
        <b>You Lack The Permissions Required To View This Page</b>
      </center>
    </div>
  </div>
</template>

<script>
//* ***** this is grid native example (there is a wrapper version) *******
import Vue from 'vue'
import Loading from '../Loading'
import { mapState } from 'vuex'
import { activeSite } from '../../vuex-actions'
import { orderBy, filterBy } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-vue-grid'
import Select from '../partials/dropdowns/buttonDropdown.vue'

import moment from 'moment'

let pageName = 'Asset Reporting - Period Details'

Vue.filter('formatDate', function (date) {
  if (date) {
    return moment(new Date(date).toISOString().substring(0, 10)).format('MMM D, YYYY')
  }
})

// https://stackoverflow.com/a/1909508
function debounce (fn, ms) {
  let timer = 0
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

export default {
  components: {
    Loading,
    'Grid': Grid,
    Select
  },
  data () {
    return {
      maxHeight: 0,
      isRegenerating: false,
      isDownloading: false,
      minResults: 0,
      isLoadingAssetReports: false,
      dates: {
        start: {
          minDate: new Date(1969, 0, 1),
          maxDate: new Date(moment().toDate())
        },
        end: {
          minDate: new Date(moment().toDate()),
          maxDate: new Date(moment().toDate())
        },
        currentDateRange: { start: new Date(moment().year(), 0, 1), end: new Date(moment().toDate()) }
      },
      reportDateRanges: [
        { text: 'Current Year', value: '1' },
        { text: 'Last Year', value: '2' },
        { text: 'Custom Date Range', value: '3' },
        { text: 'All', value: '4' }
      ],
      selectedReportDateRange: '1',
      props: {
        grid: Grid,
        field: String,
        filterType: String,
        value: [String, Number, Boolean, Date],
        operator: String
      },
      skip: 0,
      take: 20,
      pageSize: 20,
      page: 1,
      totalRecords: 0,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true,
        messages: {
          itemsPerPage: 'vendors',
          display: '{0}-{1} from {2} vendors',
          empty: 'No data',
          allPages: 'Show All'
        }
      },
      payload:
      {
        filter:
        {
          allReports: false,
          institutionId: 0,
          dateFilter:
          {
            startDate: null,
            endDate: null
          }
        },
        limit: 20,
        offset: 0
      },
      input: {
        name: '',
        reportType: '',
        user: '',
        institution: '',
        startDate: '',
        endDate: ''
      },
      reports: [],
      confirmationText: '',
      managingReportId: null,
      showTypeSelectionModal: false,
      interval: null,
      intervalCount: 5,
      isLoading: true,
      showDeleteModal: false,
      selectedField: 'selected',
      selectedRows: [],
      pageheading: pageName,
      sort: [
        { field: 'createdAt', dir: 'DESC' }
      ],
      filter: {
        logic: 'and',
        filters: []
      },
      staticColumns: [
        {
          field: 'id',
          title: 'Id',
          filterable: false,
          hidden: true
        },
        {
          field: 'reportDisplayName',
          title: 'Institution',
          cell: 'batchNameCell',
          filterable: true,
          filterCell: 'reportTypeFilterSlotTemplate'
        },
        {
          field: 'statementDate',
          title: 'Statement Date',
          width: '150px',
          cell: 'statementDateTemplate',
          filterable: false
        },
        {
          field: 'createdAt',
          title: 'Creation Date',
          width: '150px',
          cell: 'creationDateTemplate',
          filterCell: 'creationDateFilterCellTemplate',
          filterable: true
        },
        {
          field: 'status',
          cell: 'statusCell',
          title: 'Status',
          width: '150px',
          filterable: false
        },
        {
          field: 'path',
          title: 'Actions',
          filterable: false,
          sortable: false,
          width: '110px',
          cell: 'downloadTemplate'
        }
      ],
      pagination: {
        take: 10,
        skip: 0,
        total: 0
      },
      moment: moment
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName
    },
    hasSelection () {
      return this.selectedRows.length > 0
    },
    hasPluralSelection () {
      return this.selectedRows.length > 1
    },
    managingReport () {
      return this.reports.find(report => report.id === this.managingReportId)
    },
    selectedReports () {
      return this.reports.filter(report => this.selectedRows.includes(report.id))
    },
    institutionReports: function () {
      if (this.reports.length === 0) { return [] }

      let filtered = filterBy(this.reports.map(report => {
        if (this.selectedRows.includes(report.id)) {
          report.selected = true
        }

        return report
      }), this.filter)
      return orderBy(filtered, this.sort)
    },
    areAllSelected () {
      let selected = this.institutionReports.findIndex(item => item.selected === false)
      let isUndefined = this.institutionReports.findIndex(item => item.selected === undefined)

      if (this.institutionReports.length === 0) return false
      return selected === -1 && isUndefined === -1
    },
    columns () {
      return [
        { field: 'selected', width: '30px', filterable: false, headerSelectionValue: this.areAllSelected },
        ...this.staticColumns
      ]
    },
    hideDateLabel () {
      return this.selectedReportDateRange !== '3' && this.selectedReportDateRange !== '4'
    },
    pageSizes () {
      let sizes = [this.minResults]

      sizes.push(this.minResults)
      sizes.push(5)
      sizes.push(10)
      sizes.push(15)
      sizes.push(20)
      sizes.push(50)
      sizes.push(100)

      // remove dups
      sizes = [...new Set(sizes)]

      // return a sorted array from smallest to largest
      return sizes.sort((a, b) => a - b)
    }
  },
  watch: {
    managingReportId (curr, prev) {
      if (curr) this.showTypeSelectionModal = true
    }
  },
  mounted () {
    this.__setup()
  },
  unmounted () {
    clearInterval(this.interval)
  },
  updated () {
    // Manually repopulate filter values after server-side querying
    if (this.filters) {
      this.filters.forEach(element => {
        var filterInput = document.getElementById(element.field)
        if (filterInput) { filterInput.value = element.value }
      })
    }
  },
  methods: {
    reset (event, value) {
      value.showSaveDialog = false
      event.target.blur()
      this.getAssetReports()
    },
    async submitChanges (original, type, newValue) {
      let originalValue = this.reports.filter(_document => _document.id === original.id)

      if (originalValue.length > 0) {
        let response = await this.axios.put('asset-reports/' + this.$route.params.id + '/reports/' + original.id, original)

        if (response.status >= 200 && response.status < 300) {
          this.getAssetReports()
        }
      }

      this.$forceUpdate()
    },
    async downloadAll (format = 'pdf') {
      // Set loading status to true
      this.isDownloading = true

      try {
        // Start the download with axios
        const response = await this.axios({
          method: 'get',
          url: '/asset-reports/' + this.$route.params.id + '/bulk/download?format=' + format,
          headers: { 'Content-Type': 'application/json;charset=UTF-8', responseType: 'blob' },
          responseType: 'blob'
        })

        // Create a URL for the blob
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', 'Bulk-Asset-Report-Download.zip') // or another name from the response headers
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        // Clean up the blob URL
        window.URL.revokeObjectURL(downloadUrl)
      } catch (_) {
        // console.error('There was an error with the download:', error)
      } finally {
        // Set loading status to false regardless of the outcome
        this.isDownloading = false
      }
    },
    download (format = 'pdf', reportId = undefined) {
      this.isDownloading = true
      const callback = (error, result, reportId) => {
        function doNothing (e, r) {

        }

        this.showTypeSelectionModal = false
        this.managingReportId = null

        this.selectedRows = this.selectedRows.filter(id => id !== reportId)
        this.reports = this.reports.map(report => {
          if (report.id === reportId) {
            report.selected = false
          }

          return report
        })

        if (this.selectedRows.length === 0) {
          this.isDownloading = false
        }

        doNothing(error, result)
      }

      if (reportId) {
        this.api().assetReports.downloadReport({
          batchId: 0,
          reportIds: [...this.selectedRows, reportId],
          format: format
        }, (e, r) => callback(e, r, reportId))
      } else {
        if (this.selectedRows.length === 0) {
          this.selectedRows = this.reports.map(report => report.id)
        }

        this.selectedRows.forEach(reportId => this.api().assetReports.downloadReport({
          batchId: 0,
          reportIds: [reportId],
          format: format
        }, (e, r) => callback(e, r, reportId)))
      }
    },
    regenerate (report) {
      // console.log(report)
      let response = confirm('Are you sure you want to regenerate this report?')

      if (!response) return

      report.createdAt = new Date()
      report.isLoading = true

      this.api().assetReports.queueReportsToGenerate({
        period: report.statementDate,
        institutions: [report.institutionId],
        isRegenerating: 1
      }, () => {
        report.isLoading = false
        this.getAssetReports()
      })
    },
    formatDate (date) {
      if (moment.utc(date).isValid()) {
        return moment.utc(date).local().format('MM/DD/YYYY')
      }
    },
    viewReport (report) {
      this.$router.push({ name: 'ViewReport', params: { id: report.id, 'report': report } })
    },
    deleteIfConfirmed (event, data) {
      if (this.confirmationText !== 'delete') return

      // Do Delete Action in event loop
      // This will free up the UI and reload the data after each successfully deletes.
      // This provides a better user experience.
      this.selectedRows.forEach(row => {
        this.api().assetReports.deleteReport({ batchId: this.$route.params.id, reportId: row }, () => this.getAssetReports())
      })

      this.getAssetReports()
      this.confirmationText = ''
      this.showDeleteModal = false

      this.selectedRows = []
    },
    onHeaderSelectionChange (event) {
      let checked = event.event.target.checked
      Vue.set(this, 'reports', this.reports.map((item) => { return { ...item, selected: checked } }))

      if (checked) {
        this.selectedRows = this.reports
          .filter(function (e) {
            return e.selected === true
          })
          .map(function (i) {
            return i.id
          })
      } else {
        this.selectedRows = []
      }
    },
    openDeleteModal () {
      this.showDeleteModal = true
    },
    onSelectionChange (event) {
      let checked = event.event.target.checked
      const id = event.dataItem.id
      if (checked) {
        this.selectedRows.push(id)
      } else {
        var idx = this.selectedRows.indexOf(id)
        if (idx > -1) {
          this.selectedRows.splice(idx, 1)
        }
      }

      event.dataItem.selected = checked
    },
    stripDate: function (dateString) {
      // 2016-02-24T00:11:00Z to 2016-02-24
      // Note this is needed to prevent off-by-one date errors due to the timestamp and timezone offset
      return dateString.substring(0, 10)
    },
    pageChangeHandler: function (event) {
      this.pagination.skip = event.page.skip
      this.pagination.take = isNaN(event.page.take) ? this.pagination.total : event.page.take
      this.perPage = isNaN(event.page.take) ? 'All' : event.page.take
      this.pageSize = isNaN(event.page.take) ? 'All' : event.page.take

      localStorage.setItem('reportPageSize', event.page.take)
      this.getAssetReports()
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort
      localStorage.setItem('reportSort', JSON.stringify(this.sort))
      this.getAssetReports()
    },
    filterChangeHandler: debounce(function (e) {
      this.filters = [{
        field: e.event.field,
        operator: 'contains',
        value: e.event.value
      }]

      localStorage.setItem('batchreportFilters', JSON.stringify(this.filters))
      this.skip = 0
      this.getAssetReports(false)
    }, 500),
    clearFilters: function () {
      // Manually clear filter values
      if (this.filters) {
        this.filters.forEach(element => {
          var filterInput = document.getElementById(element.field)
          if (filterInput) { filterInput.value = '' }
        })

        this.filters = []
      }

      this.pagination.skip = 0
      this.sort = [
        { field: 'uploadDate', dir: 'asc' }
      ]

      localStorage.removeItem('batchreportFilters')
      localStorage.removeItem('reportSort')

      this.getAssetReports(false)
    },
    onStartDateRangeChange: function (e) {
      // Do nothing
    },
    onEndDateRangeChange: function (e) {
      // Do nothing
    },
    onReportDateRangeSelect: async function (e) {
      // Do nothing
    },
    getAssetReports () {
      this.isLoadingAssetReports = true
      let batchNameFilter = this.filters ? this.filters.find(x => x.field === 'reportDisplayName') : {}
      let batchDateFilter = this.filters ? this.filters.find(x => x.field === 'createdAt') : {}
      this.api().assetReports.listAssetReportsInBatch({
        take: this.pagination.take,
        skip: this.pagination.skip,
        sort: this.sort[0].field,
        desc: this.sort[0].dir,
        batchName: batchNameFilter !== undefined ? batchNameFilter.value : '',
        batchId: this.$route.params.id,
        createdAt: batchDateFilter ? batchDateFilter.value : ''
      }, (error, result) => {
        if (error) {
          this.handleApiErr(error)
        } else {
          // console.log(result)
          this.reports = result.records.map(report => {
            report.selected = false
            report.showSaveDialog = false
            return report
          })
          this.pagination.total = result.pagination.totalRecords
          this.$refs.reportGrid.$el.querySelectorAll('[type="checkbox"]').forEach(checkbox => {
            checkbox.checked = false
          })
          this.$forceUpdate()
        }

        this.isLoading = false
        this.isLoadingAssetReports = false
      })
    },
    setupReportDisplay () {
      let that = this
      this.$nextTick(() => {
        let elements = [
          '.has-text-centered.has-background-primary',
          '.top-bar',
          '.top-bar-spacer',
          '.bottom-bar',
          '.bottom-bar-spacer'
        ]

        let func = async (results) => {
          let [numberOfResults, maxHeight] = results

          if (this.minResults && this.maxHeight && (numberOfResults === null || maxHeight === null)) {
            return
          }

          this.pagination.take = numberOfResults
          this.pagination.perPage = numberOfResults
          this.pagination.minResults = numberOfResults
          this.maxHeight = maxHeight

          this.getAssetReports()
        }

        that.getListHeights('body-content-area', 40, 79, elements, func.bind(this))

        window.addEventListener('resize', () => {
          that.getListHeights('body-content-area', 40, 79, elements, func.bind(that))
        })
      })
    },
    async consumeLocalStorage () {
      if (localStorage.reportSkip) {
        this.skip = JSON.parse(localStorage.getItem('reportSkip'))
        localStorage.removeItem('reportSkip')
      }

      // if (localStorage.reportFilters) { this.filters = JSON.parse(localStorage.getItem('reportFilters')) }

      // if (localStorage.reportSort) { this.sort = JSON.parse(localStorage.getItem('reportSort')) }

      // if (localStorage.reportSelection) { await this.loadQueryParams(JSON.parse(localStorage.getItem('reportSelection'))) }

      if (localStorage.reportPageSize) {
        let pageSize = JSON.parse(localStorage.getItem('reportPageSize'))
        this.pagination.take = pageSize
        this.pagination.perPage = pageSize
        this.pagination.pageSize = pageSize
      }
    },
    __setup () {
      this.consumeLocalStorage()
      this.setupReportDisplay()
      this.getAssetReports()

      // this.interval = setInterval(() => {
      //   this.intervalCount--
      //   if (this.intervalCount === 0) {
      //     this.getAssetReports()
      //     this.intervalCount = 5
      //   }
      // }, 1000)
    },
    unmounted () {
      clearInterval(this.interval)
    }
  }
}
</script>

<style scoped>
.link {
  cursor: pointer;
  color: #7fb942;
}

.link:hover {
  color: #e69138;
}

.k-link {
  color: #000!important;
}

.k-dropdown {
  width: auto!important;
  min-width: 200px;
}

#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
.k-widget k-dateinput k-state-default k-label{
  font-weight: 600 !important;
  padding: 8px;
}
.k-checkbox:checked{
  background-color:  #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-pager-numbers .k-state-selected{
  background-color:  #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-grid th{
  font-weight: 600 !important;
}
.k-grid td.k-state-selected, .k-grid tr.k-state-selected > td{
  background-color: #CBE3B3;
  opacity: 0.8;
  font-weight: 600;
}
.k-list .k-item.k-state-selected{
   background-color:  #7fb942;
}
.k-list .k-item.k-state-selected:hover{
  background-color: #CBE3B3;
  color: #0d0c0c
}
.k-date-picker{
  width: 90%;
  margin-right: 6px;
  border: pink;
}
a.download, a.bcc-color{
  color: #7fb942 !important;
}
.page-subtitle{
  color: #7fb942;
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 8px;
}
.reportDateStatus, .status {
    margin-top: 10px;
}
.k-invalid-msg {
    display: inline-block;
}
.invalid {
    color: red;
}
.valid {
    color: green;
}
.bcc-color{
   color: #7fb942;
}
.grid-button .button {
  margin-bottom: 5px;
}
.k-grid td:first-child {
  color: transparent!important;
}

.k-grid th:first-child {
  color: transparent!important;
}

.hidden {
  display: none;
}

.visible {
  visibility: none;
}

#select-type-modal {
  z-index: 1000!important;
}

.k-master-row > td:first-child {
  color: transparent!important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popover-contain {
  padding: .25rem;

  z-index: 50;
}

.editable:hover {
    -webkit-box-shadow: 0px 0px 4px 1px #AEAEAE;
    box-shadow: 0px 0px 4px 1px #AEAEAE;
    cursor: pointer;
  }

  .editable button {
    visibility: hidden;
    background: white;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    color: #7fb841;
    padding: 5px;
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .saving {
    background: #d9d9d9!important;
  }

  .editable input {
    width: 100%;
  }

  .editable select {
    width: 100%;
  }

  .editable input:focus ~ button {
    visibility: visible;
  }

  select {
    appearance: none;
    padding: 5px;
    border: none;
    font-family: inherit;
    outline: none;
  }

  .editable input, .editable select {
    background-color: transparent;
    border: 0px;
    cursor: pointer;
  }

.tooltip .tooltip-inner {
  background: white!important;
  color: #656565;
  padding: 0rem;

  border-radius: .25rem;
}

.tooltip  {
  background: white!important;
  padding: 0rem;

  border-radius: .25rem;
  border: 1px solid #e2e2e2;
}

.v-popover .trigger {
  width: 100%;
}
</style>
